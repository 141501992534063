@import url('../../../../../styles/customMediaQueries.css');

.root {
  margin-top: var(--n-size-3);
}

.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 33.33%;
  margin-bottom: 10px;
}

.sectionContent {
  & p {
    margin: 0;
  }
}

.mainPrice {
  margin-top: 10px;
  padding: 0;

  font-family: Montserrat, sans-serif;
  font-size: 32px;
  font-weight: 600;
  color: var(--n-color-text-dark);
}

.cutPrice {
  margin-left: 15px;
  padding: 0;

  font-family: Montserrat;
  font-size: var(--n-font-size-1);
  font-weight: 600;
  color: var(--n-color-text-subtle);
  text-decoration: line-through;
}

.discount {
  margin: 0 5px;
  padding: 0;

  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-0);
  font-weight: 600;
  color: var(--n-color-text-dark);
}

.attribute {
  padding: 0;
  font-weight: 200;
}

.divider {
  height: 1px;
  background: var(--n-color-border-active);
}

.label {
  align-items: center;

  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-0);
  font-weight: 500;
  color: var(--n-color-text-subtle);

  @media (width >= 1024px) {
    width: 35%;
    min-width: 120px;
  }

  @media (width >= 1501px) {
    width: 25%;
  }
}

.value {
  display: block;

  margin-top: calc(-1 * var(--n-size-2));

  font-family: Montserrat, sans-serif;
  font-size: var(--n-font-size-0);
  font-weight: 400;

  @media (width >= 1024px) {
    margin-top: unset;
  }
}

.messageButton {
  display: flex;
  gap: var(--n-size-1);
  align-items: center;
  justify-content: center;

  height: 38px;
  padding: var(--n-size-1) 38px;

  color: black;

  background: var(--n-color-white);
  border: 1px solid var(--n-color-text-subtle);
  border-radius: var(--radius-full);

  @media (width >= 1024px) {
    display: none;
  }
}

.providerAvatar {
  display: inline;

  width: 40px;
  height: 40px;
  margin-right: 10px;

  text-decoration: none;

  @media (width >= 1024px) {
    display: flex;
    margin-right: 10px;
  }
}

.container {
  display: grid;
  gap: var(--n-size-3);
  align-items: center;
  margin: var(--n-size-3) 0;

  @media (width >= 1024px) {
    grid-template-columns: auto 1fr;
    gap: var(--n-size-3) var(--n-size-2);
    align-items: start;
  }
}
