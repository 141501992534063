@import url('../../styles/customMediaQueries.css');

* {
  box-sizing: border-box;
}

.pageRoot {
  padding-bottom: 90px;

  @media (width >= 1024px) {
    padding-bottom: 0;
  }
}

.loadingText {
  display: grid;
  place-items: center;
  margin: var(--n-size-10) var(--n-size-3);
}

.errorText {
  margin: var(--n-size-3);
  color: var(--colorFail);
}

.mainColumnForProductLayout {
  overflow: hidden;
  display: grid;
  place-content: center;
  max-width: 100%;
}

.mainColumnForProductLayout::-webkit-scrollbar {
  background: transparent;
}

.orderColumnForProductLayout {
  padding: 20px;

  @media (width >= 1024px) {
    overflow-y: scroll;
    height: 100%;
  }

  @media (width >= 1024px) {
    padding: 0;
  }
}

.orderColumnForProductLayout::-webkit-scrollbar {
  display: none;
}

.originalSellerLabel {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-transform: uppercase;
}

.originalSellerLabel > * {
  display: grid;
  place-items: center;

  padding: var(--n-size-1) var(--n-size-2);

  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-0);
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  text-decoration: none;

  border-bottom: 2px solid transparent;

  transition: border-bottom 0.2s ease;

  &[aria-selected='true'] {
    border-bottom: 2px solid black;
  }

  &[aria-selected='false']:hover {
    text-decoration: none;
    border-bottom: 2px solid #eef1f5;
  }
}

.contentWrapperForProductLayout {
  overflow: hidden;

  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  margin-top: var(--n-size-1);

  @media (width >= 1024px) {
    display: grid;
    grid-template-columns: minmax(auto, 3fr) 2fr;
    gap: var(--n-size-10);

    margin-top: var(--n-size-3);
    padding: 0 var(--n-size-10) var(--n-size-5);
  }
}

.sectionWrapper {
  overflow-x: hidden;
  margin: var(--n-size-5) 0;
}

.productOrderPanel {
  @media (width >= 1024px) {
    display: block;
  }

  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (width >= 1024px) and (height >= 950px) {
    position: sticky;
  }
}

.sectionTitle {
  margin: 0 0 var(--n-size-5);

  font-family: var(--fontFamilySyne);
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0;
}

.authorNameLink {
  color: var(--colorGrey700);
}

.longWord {
  hyphens: auto;

  /* fallback option */
  word-break: break-all;

  /* use break-word if available */
  word-break: break-word;
}

.sectionCommitments {
  display: grid;
  margin-bottom: var(--n-size-10);
  padding: 0 var(--n-size-2);

  & h3,
  & p {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  & h3 {
    font-family: var(--fontFamilySyne);
    font-size: var(--n-font-size-0);
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0;
  }

  & p {
    justify-self: center;

    max-width: 362px;

    font-family: Montserrat;
    font-size: var(--n-font-size-00);
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0;
  }
}

.sectionCommitments .sectionTitle {
  @media (width >= 1024px) {
    margin-bottom: var(--n-size-8);
  }
}

.sectionCommitments__content {
  display: grid;
  gap: var(--n-size-5);
  width: 100%;

  & > * {
    display: grid;
    gap: var(--n-size-2);
  }

  @media (width >= 1024px) {
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    max-width: 1424px;
    margin: 0 auto;
  }
}
