.toastContent {
  position: relative;

  overflow: hidden;

  padding: var(--n-size-2);

  color: var(--n-font-color-base, #333);

  background-color: white;
  border-radius: var(--n-size-half);
}

.toastBody {
  margin: 0;
  font-size: var(--n-font-size-00);
}

.toastMessage {
  margin-top: 0;
  margin-bottom: var(--n-size-quarter);
  margin-bottom: 0.5rem;

  line-height: 1.4;
  white-space: pre-wrap;
}

.toastMessage:last-child {
  margin-bottom: 0;
}

.toastCloseButton {
  cursor: pointer;

  position: absolute;
  top: var(--n-size-half);
  right: var(--n-size-half);

  font-size: var(--n-font-size-2);
  font-weight: bold;
  line-height: 1;
  color: var(--n-color-grey-dark, #888); /* Fallback to #888 */

  background-color: transparent;
  border: none;
}

.toastCloseButton:hover {
  color: var(--n-color-grey-darker, #555); /* Fallback to #555 */
}
