@import url('../../../styles/customMediaQueries.css');

/* Order form inside modalContainer needs special handling */

/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  @media (width >= 768px) {
    flex-basis: 576px;
    height: unset;
    background-color: var(--colorWhite);
  }

  @media (width >= 1024px) {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    background-color: transparent;
  }
}

.modalHeading {
  @media (width >= 768px) {
    padding: 0;
  }

  @media (width >= 1024px) {
    display: none;
  }
}

.topInfoSection {
  display: grid;
  gap: var(--n-size-1);

  & > * {
    margin: 0;
    padding: 0;
    line-height: 1.5;
  }
}

.brandName {
  margin: 0;
  padding: 0;

  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-000);
  font-weight: 700;
  line-height: 1.5;
  color: var(--n-color-text-subtle);
  text-transform: uppercase;
}

.status {
  margin-bottom: var(--n-size-3);
  padding: var(--n-size-3);

  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-1);
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;

  background-color: var(--n-color-border);
  border-radius: var(--radius-md);
}

.heading {
  align-self: stretch;

  margin: 0;

  /* Heading/H4 */
  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-3);
  font-weight: 700;
  color: var(--n-color-text-dark);
  text-transform: uppercase;
}

.prices {
  display: grid;
  grid-template-columns: auto auto;
  gap: var(--n-size-2);
  justify-content: start;

  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-3);
  font-weight: 700;
  line-height: 29px;
  text-align: left;
  letter-spacing: 0;
}

.prices > *:nth-child(2) {
  font-size: var(--n-font-size-2);
  color: var(--n-color-text-subtle);
  text-decoration: line-through;
  white-space: pre;
}

.price {
  composes: h4 from global;
  margin: 0 0 0 var(--n-size-3);
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColor);

  @media (width >= 768px) {
    margin: 0;
    padding: 0;
  }
}

.inquiryForm {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: var(--n-size-3);
  height: 100%;

  @media (width >= 768px) {
    width: 500px;
    max-width: 100%;
  }
}

.perUnit {
  /* Font */
  composes: h4 from global;

  margin-top: 0;
  margin-bottom: 0;
  padding: 0;

  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColor);

  @media (width >= 768px) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.author {
  composes: p from global;

  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 0 var(--n-size-3) var(--n-size-4);

  @media (width >= 768px) {
    width: 100%;
    margin: 12px 0 36px;
    color: var(--colorGrey700);
  }
}

.providerAvatar {
  display: none;

  @media (width >= 1024px) {
    display: flex;
    margin-right: 10px;
  }
}

.providerNamePlain {
  display: inline;

  @media (width >= 1024px) {
    display: none;
  }
}

.providerNameLinked {
  display: none;

  @media (width >= 1024px) {
    display: inline;
  }
}

.orderHelp {
  composes: h5 from global;
  display: none;

  @media (width >= 768px) {
    display: block;
    margin-top: 0;
    color: var(--colorGrey700);
  }
}

.bookingForm {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0;

  @media (width >= 768px) {
    min-height: 400px;
    padding: 0;
  }

  @media (width >= 1024px) {
    min-width: 312px;
    min-height: auto;
  }
}

.openOrderForm {
  position: fixed;

  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  bottom: 0;
  left: 0;

  /* Contain repainting to this component only */

  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  display: flex;

  width: 100vw;
  padding: 18px var(--n-size-3) 18px var(--n-size-2);

  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);

  @media (width >= 768px) {
    padding: 18px 60px;
  }

  @media (width >= 1024px) {
    display: none;
  }
}

.priceContainerInCTA,
.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  gap: 4px;
  align-items: baseline;

  margin-right: 22px;
  padding: 3px 0;

  @media (width >= 768px) {
    padding: 4px 0;
  }

  @media (width >= 1024px) {
    flex-direction: row;
    margin-right: unset;
    padding: 0;
  }
}

.priceContainerInCTA {
  flex-direction: column;
  gap: unset;
  align-items: center;
  justify-content: center;
}

.priceValue {
  /* Font */
  composes: h3 from global;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--marketplaceColor);

  @media (width >= 768px) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnitInCTA {
  /* Font */
  composes: marketplaceTinyFontStyles from global;

  margin-top: 0;
  margin-bottom: 0;
  padding: 0;

  font-weight: var(--fontWeightSemiBold);
  color: var(--marketplaceColor);

  @media (width >= 768px) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.closedListingButton {
  width: 100%;
  padding: 15px var(--n-size-3);
  text-align: center;
  border-left: 1px solid var(--colorGrey100);
}

.receipt {
  flex-shrink: 0;
}

.error {
  composes: h4 from global;
  padding: 0 var(--n-size-3);
  color: var(--colorFail);

  @media (width >= 768px) {
    padding: 0;
  }
}

.errorSidebar {
  padding: 0 var(--n-size-3);
  color: var(--colorFail);

  @media (width >= 768px) {
    padding: 0;
  }
}

.soldByWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.soldByLabel {
  margin-right: 6px;
  padding: 0;
  font-weight: 200;
}

.commentWrapper {
  margin-bottom: 20px;
}

.commentLabel {
  margin-right: 20px;
  font-weight: var(--fontWeightRegular);
  color: #808080;
}

.commentContent {
  padding: 0;
  font-weight: 200;
}

.button {
  text-transform: uppercase;
}
