.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  padding: 0 20%;

  /* margin-left: 10%; */

  /* margin-right: 10%; */
}

.tabsNav {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin-bottom: 20px;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-top: var(--n-size-2);
}

.tab {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  width: 100%;
  padding: 20px 30px;

  font-size: var(--n-font-size-00);
  font-weight: 300;
  color: black;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;

  border-bottom: 3px solid rgb(170 170 170);

  &:hover {
    border-bottom: 3px solid black;
  }

  &:hover,
  &:focus {
    color: var(--colorBlack);
    text-decoration: none;
  }
}

.tabSelected {
  composes: tab;
  border-bottom: 3px solid black;
}

.content {
  width: 100%;
  margin-right: 4%;
  margin-left: 4%;
}

th,
td {
  padding: var(--n-size-1);
  text-align: center;
  border: 1px solid black;
}

th {
  background-color: #f2f2f2;
}

td {
  padding: 15px;
}

.smallIcon {
  width: 16px;
  height: 19px;
}

.icon {
  width: 45px;
  height: 45px;
}

.saving {
  margin: 0;
  padding: 0;

  font-size: var(--n-font-size-000);
  font-weight: var(--fontWeightMedium);
  font-weight: 300;
  line-height: 18px;

  @media (width >= 768px) {
    padding: 1px 0 7px;
    font-size: var(--n-font-size-0);
  }
}

.shipping {
  margin-bottom: 12px;
  font-size: var(--n-font-size-00);
  font-weight: 300;
  line-height: 24px;
}

.returns {
  margin-top: 12px;
  margin-bottom: 12px;

  font-size: var(--n-font-size-00);
  font-weight: 300;
  line-height: 24px;
}

.cost {
  margin: 0;

  font-size: var(--n-font-size-00);
  font-weight: 300;
  line-height: 24px;
  color: #808080;
}

.label {
  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-1);
  font-weight: 400;
}

.infoSmall {
  margin: 0;
  font-size: var(--n-font-size-00);
  font-weight: 300;
  line-height: 24px;
}

.accordionRoot {
  display: grid;

  & h3 {
    margin: 0;
    padding: 0;
  }
}

.accordionItem__header {
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: var(--n-size-1);
  align-items: center;
  justify-content: space-between;

  width: 100%;
  min-width: 200px;
  padding: var(--n-size-2) 0;

  font-family: var(--fontFamilySyne);
  font-size: var(--n-font-size-0);
  font-weight: 700;

  /* Filter Heading */
  line-height: normal;
  line-height: 19px;
  color: var(--n-color-text-dark);
  text-align: left;
  letter-spacing: 0;

  border: none;
  border-bottom: 1px solid var(--n-color-border);

  & svg {
    transition: transform 0.1s ease;
  }

  &[data-state='open'] {
    border-bottom: none;
  }

  &[data-state='open'] svg {
    transform: rotate(-180deg);
  }
}

.accordionItem__content {
  overflow: hidden;

  &[data-state='open'] {
    border-bottom: 1px solid var(--n-color-border);
    animation: slideDown 0.1s ease forwards;
  }

  &[data-state='closed'] {
    animation: slideUp 0.1s ease forwards;
  }
}

@keyframes slideDown {
  from {
    height: 0;
  }

  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }

  to {
    height: 0;
  }
}
